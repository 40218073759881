import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

//university logos
import GranadaLogo from "../../../Assets/IvyLeague/GranadaLogo.png";
import barcelonaLogo from "../../../Assets/IvyLeague/BracelonaLogo.jpg";
import pompeuLogo from "../../../Assets/IvyLeague/pompeuLogo.png";

//icons
import innovationIcon from "../../../Assets/innovation.png";
import workIcon from "../../../Assets/work oppurtunity.png";
import university from "../../../Assets/icons8-university-48.png";
import spanishCulture from "../../../Assets/spanishCulture.webp";
import sports from "../../../Assets/sports.png";

function Spain() {
  const data = {
    countryName: `Spain`,
    titleTag: `Study In Spain For International Students | Cost of Living in Spain | Why study in  | Confluence Educational Services`,
    aboutCountry: `Approximately 8,000 Indian students study in Spain, drawn by affordable tuition (€1,000-€5,000/year) and diverse programs.  Spain’s got a team called the National Agency for Quality Assessment and Accreditation of Spain (ANECA) that makes sure your education is top-notch.`,
    WhyStudyinThatCountry: [
      { icon: spanishCulture, text: "Indulge in Spanish Culture" },
      { icon: workIcon, text: "Work and explore" },
      { icon: sports, text: "Celebrate Sports" },
      { icon: innovationIcon, text: "Research and Innovation" },
      { icon: university, text: "Renowned Universities" },
    ],
    TopCities: [
      {
        city: "Barcelona",
        description:
          "Facing the Mediterranean to the southeast, Barcelona is located on a plain generally confined by the Besós River (north) and the Llobregat River (south). Barcelona's semicircle of mountains protects the city from strong winds coming from the north and west, contributing to its pleasant temperature. With January being the coldest month at 49°F (9°C) and August being the hottest at 76°F (24°C), the average yearly temperature is 61°F (16°C).",
      },
      {
        city: "Bilbao",
        description:
          "Situated in the Basque Country of northern Spain, Bilbao is encircled by verdant hills and is tucked away along the Nervión River. It has a thriving cultural environment, with the Guggenheim Museum showcasing modern and contemporary art as its focal point. In addition, Bilbao is well known for its delicious tapas, or pintxos, in the Basque manner.",
      },
      {
        city: "Madrid",
        description:
          "The capital of Spain, Madrid, is located in the center of the Iberian Peninsula. The city is well known for its extensive cultural legacy, which includes well-known sites like Retiro Park, the Royal Palace, and the Prado Museum. Madrid is made more appealing by its varied food scene, lively nightlife, and historic neighborhoods like Malasaña.",
      },
      {
        city: "Malaga",
        description:
          "Málaga, which is on Spain's Costa del Sol, has a gorgeous coastline and a Mediterranean climate. Pablo Picasso was born in Málaga, a city renowned for its rich cultural legacy that also houses the Picasso Museum and the Alcazaba fortress. The city boasts a bustling port sector, modern conveniences, and historic charm. The University of Málaga provides a variety of programs in subjects like business, engineering, and social sciences to international students.",
      },
      {
        city: "Granada",
        description:
          "Granada is renowned for its distinctive flamenco tradition and arts scene. The city is well known for its colorful flamenco shows and the yearly International Festival of Flamenco, which highlights the rich cultural heritage of the genre. The University of Granada also attracts students who are enthusiastic about this classic Spanish art form with its specialized courses in Flamenco Dance and Music.",
      },
      {
        city: "Valencia",
        description:
          "Valencia invites you to celebrate another important recognition of the city's commitment to sustainability: its election as European Green Capital 2024. The European Commission granted this distinction in recognition of the city's efforts to enhance the environment and the standard of living for both locals and visitors.",
      },
      {
        city: "Seville",
        description:
          "Southern Spain's Seville is well-known for its lively environment and rich cultural legacy. The city is well-known for its magnificent landmarks, which include the Alcázar castle and the Giralda Tower in Seville. Seville is also a center for traditional Spanish arts, particularly flamenco, which is celebrated at several festivals and locations throughout the city. Specialized courses in Flamenco Studies and Spanish Dancing are available at the University of Seville.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "University of Granada (UGR)",
        description:
          "The University of Granada (UGR) provides international students with excellent academic possibilities and practical experience. It is notable for its research prowess, especially in science and technology. UGR provides a vibrant learning environment and accommodates a wide range of academic interests through its diversified range of programs. Situated in the culturally vibrant city of Granada, the institution offers a more cost-effective living alternative when compared to other major cities in Spain. Granada's cultural offerings and historic charm, along with the lively campus life, make for a comprehensive and captivating educational experience for students. UGR is a great option for international students because of its vibrant international population, which only serves to increase its appeal.",
        logo: GranadaLogo,
      },
      {
        university: "University of Barcelona (UB)",
        description:
          "The highly regarded University of Barcelona (UB), which is situated in Barcelona, Spain, is frequently included among the top 150 universities in the world. Notable programs in Business and Economics, Biomedical Sciences, Arts and Humanities, and Environmental Sciences are available at UB, which is renowned for its excellent academic standards. A vibrant and diversified community that offers several English-language programs as well as possibilities to learn Spanish or Catalan is advantageous to international students. The institution offers a strong basis for academic and professional progress because of its well-established international reputation and demanding research opportunities. Barcelona's diverse cultural scene, agreeable temperature, and wealth of support services all contribute to the overall student experience, which is why UB is a top option for anybody looking for an exciting and fulfilling education overseas.",
        logo: barcelonaLogo,
      },
      {
        university: "University of Pompeu Fabra (UPF)",
        description:
          "A prestigious academic institution known for its emphasis on research and excellent standards is the University of Pompeu Fabra (UPF) in Barcelona, Spain. Situated in the bustling city center, UPF provides a wide range of studies in Technology, Humanities, and Social Sciences. For overseas students, the university offers a range of scholarships, including need- and merit-based grants. With a reputation for being hospitable, UPF provides a wide range of student support services like academic advice and orientation events. Data science, political science, and international business are among the accredited courses. International students are guaranteed a rich and encouraging experience at the university thanks to its robust worldwide network and vibrant urban setting.",
        logo: pompeuLogo,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };

  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>
          <ul>
            <li>
              Ensure that the course or program you're enrolling in fits the
              Spanish authorities' requirements for a student visa.
            </li>
            <li>
              The applicant must get an acceptance letter of enrollment from an
              accredited Spanish Education Institutions.
            </li>
            <li>
              Demonstrate enough financial resources to cover the tuition and
              living cost and also get the health insurance from a private firm
              or through the Spanish National Health System.
            </li>
            <li>
              Police clearance certificate or similar documentation to show a
              clean record or history from the home country.
            </li>
          </ul>
          <h6>Documents required</h6>

          <ul>
            <li>A copy of Passport</li>
            <li>A copy of academic transcripts and diplomas [All levels]</li>
            <li>Copy of IELTS/TOEFL Score Card</li>
            <li>Passport Size Photograph</li>
            <li>Curriculum Vitae</li>
            <li> Letter of Motivation</li>
            <li> Recommendation Letters-3</li>
            <li> Experience Documents</li>
          </ul>

          <h6>English proficiency</h6>
          <p>
            Spanish universities recognise IELTS, TOEFL (IBT), and PTE. The
            applicants must obtain the appropriate score on the English
            proficiency test. An individual must score at least 6.5 bands on the
            IELTS. To be accepted into educational institutions, the TOEFL score
            should be between 80 and 100, and the PTE score should be between 58
            and 65.
          </p>
          <h6>Application</h6>
          <p>
            The applicant has to download a Schengen visa application from the
            Spanish Consulate or Embassy website. A valid passport that is at
            least 3 months beyond the stay in Spain with at least leaving two
            blank pages. A recent passport-sized photocopy as per the Spanish
            requirements. You may have to provide biometrics during the
            appointment. In some cases, the applicants might be required to
            attend an interview about their stay and study-related information.
          </p>
          <h6>Decision</h6>
          <p>
            Visa processing usually takes a few weeks, though this can vary.
            Check with the consulate for an expected processing time. Once
            approved, you will be notified to pick up your visa. Before leaving
            the consulate or embassy, be considered sure that all of your
            information is correct.
          </p>
        </div>
      </div>

      <FeeStructureTables countryName={"Spain"} />
    </div>
  );
}

export default Spain;
