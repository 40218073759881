import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

//university logos
import GalwayLogo from "../../../Assets/IvyLeague/GalwayLogo.png";
import LimeRickLogo from "../../../Assets/IvyLeague/LimeRickLogo.png";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

//icons
import English from "../../../Assets/English.jpg";
import ItHub from "../../../Assets/ItHub.webp";
import pharmacyIcon from "../../../Assets/pharmacyIcon.jpg";
import IndependentLearning from "../../../Assets/IndependentLearning.png";
import friendlyEnvironment from "../../../Assets/friendlyEnvironment.png";
import work from "../../../Assets/work oppurtunity.png";

function Ireland() {
  const data = {
    countryName: `Ireland`,
    titleTag: `Study In Ireland For International Students | Cost of Living in Ireland | Why study in  | Confluence Educational Services`,
    aboutCountry: `Roughly 5,000 Indian students are enrolled in Irish universities as of the most recent reports. The fact that this number represents a sizable percentage of the global student body shows how attractive Ireland is to Indian students in light of its robust educational programs and friendly atmosphere.`,
    WhyStudyinThatCountry: [
      { icon: English, text: "English Speaking Country" },
      { icon: ItHub, text: "IT Hub" },
      { icon: pharmacyIcon, text: "Home of Pharmacy industry" },
      { icon: friendlyEnvironment, text: "Friendly environment" },
      { icon: IndependentLearning, text: "Independent Learning" },
      { icon: work, text: "High Employment Prospect" },
    ],
    TopCities: [
      {
        city: "Dublin",
        description:
          "Dublin, Ireland's capital, is located on the east coast and is well-known for its rich literary past, thriving technology scene, and cultural diversity. It is home to prestigious universities such as Trinity College Dublin and University College Dublin, which draw international students studying computer science, commerce, and literature. The city's strong ties to major digital companies like Google and Facebook provide important internship and job possibilities.",
      },
      {
        city: "Waterford",
        description:
          "Waterford, Ireland's oldest city, is situated in the southeast on the Suir River. The city, famous for its Viking history and as the birthplace of Waterford Crystal, combines a rich tradition with a thriving modern economy. Waterford Institute of Technology (WIT) is a popular choice among international students because of its strong engineering, business, and technology degrees. The city's small size produces a close-knit community, making it a friendly atmosphere for students.",
      },
      {
        city: "Galway",
        description:
          "Galway, on Ireland's west coast, is renowned for its robust arts scene, historic charm, and dynamic environment. Galway, nicknamed the 'Cultural Heart of Ireland,' is well-known for its traditional music, arts festivals, and friendly residents. The National University of Ireland Galway (NUI Galway) attracts international students with its strong programs in biomedical science, marine biology, and Irish studies. The city's position provides unique chances for study in environmental and marine sciences.",
      },
      {
        city: "Cork",
        description:
          "Cork is recognized as Ireland's culinary capital, and visitors are encouraged to indulge. The excellent English Market is the best place to start exploring Cork's cuisine culture. Every October, hundreds of jazz musicians and thousands of music fans flock to Cork from all over the world for Ireland’s biggest jazz festival, the Guinness Cork Jazz Festival. Experience life in the city in the 19th and early 20th century with a trip to Cork City Gaol, a museum that offers a unique insight into Cork’s history.",
      },
      {
        city: "Drogheda",
        description:
          "Located along the Boyne River, Drogheda has a mix of historical and modern attractions, including the impressive St. Laurence Gate and nearby ancient sites like Newgrange. The city's rich heritage combined with modern developments creates a unique environment. While Drogheda is smaller compared to other Irish cities, its proximity to Dublin and historic sites makes it an appealing location for those interested in history, archaeology, and a quieter pace of life.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "National University of Ireland Galway (NUI Galway)",
        description:
          "Located in Galway, the National University of Ireland Galway (NUI Galway) is a prestigious public institution established in 1845. NUI Galway is well-known for its outstanding research, with a focus on marine science, biomedical science, and engineering. It provides both undergraduate and graduate studies. The campus features state-of-the-art amenities and a thriving student body with several organizations and societies. Prominent alumni include former president Mary Robinson and Nobel laureate Seamus Heaney. The university promotes strong industrial and international partnerships, offering students numerous opportunities for real-world experience and global engagement.",
        logo: GalwayLogo,
      },
      {
        university: "University of Limerick (UL)",
        description:
          "Located beside the Shannon River, the University of Limerick (UL) is one of Ireland's top public universities, founded in 1972. UL is renowned for its emphasis on real-world experience and industry connections. It offers a wide range of undergraduate, graduate, and doctorate degrees, focusing on engineering, business, and applied sciences. The state-of-the-art campus supports a vibrant student life with numerous organizations and activities. Thanks to UL's close ties to business, students benefit from valuable internship and teamwork opportunities.",
        logo: LimeRickLogo,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };
  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <FeeStructureTables countryName={"Ireland"} />
    </div>
  );
}

export default Ireland;
