import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

//university logos
import DelftLogo from "../../../Assets/IvyLeague/DelftLogo.png";
import LeidenLogo from "../../../Assets/IvyLeague/Leiden-University-logo.png";
import EindhovenLogo from "../../../Assets/IvyLeague/Eindhoven_University_of_Technology_logo.png";

//icons
import nature from "../../../Assets/nature.png";
import tulip from "../../../Assets/tulipGarden.png";
import dutchIcon from "../../../Assets/bilingual.png";
import civilIcon from "../../../Assets/Civil.png";

function Netherlands() {
  const data = {
    countryName: `Netherlands`,
    titleTag: `Study In Netherlands For International Students | Cost of Living in Netherlands | Why study in  | Confluence Educational Services`,
    aboutCountry: `The most recent data indicates that 122,287 foreign students are enrolled in all degree levels at Dutch universities. 36,991 students were enrolled in graduate programs and 85,296 students in undergraduate programs.`,
    WhyStudyinThatCountry: [
      { icon: nature, text: "Scenic Beauty" },
      { icon: dutchIcon, text: "Become a Dutch Master" },
      { icon: tulip, text: "Tulip Garden" },
      { icon: civilIcon, text: " Canals and Civil Engineering" },
    ],
    TopCities: [
      {
        city: "Amsterdam",
        description:
          "The Netherlands' capital, Amsterdam, is situated in the western portion of the nation. It is well-known for its charming canals, storied buildings, and active cultural scene. Renowned museums include the Anne Frank House, the Van Gogh Museum, and the Rijksmuseum. One of the UNESCO World Heritage sites is the city's canal system. The average temperature of Amsterdam is between 6°C (43°F) in the winter and 16°C (61°F) in the summer.",
      },
      {
        city: "Rotterdam",
        description:
          "Rotterdam, a prominent port city in the southwest of the Netherlands, is well-known for its cutting-edge urban design and contemporary architecture. Famous landmarks include the Erasmus Bridge and the Cube Houses. Rotterdam has one of the biggest ports in Europe, highlighting its economic importance. The city experiences mild to warm summers and comparatively cool winters due to its temperate coastal climate, with winter temperatures ranging from 4°C (39°F) to 17°C (63°F) in the summer.",
      },
      {
        city: "Hague",
        description:
          "The Hague, the Dutch government's capital, is situated on the country's western coast and is home to the International Court of Justice. It is well-known for cultural establishments like the Mauritshuis Museum and seaside resorts. The Hague has a moderate marine climate, with mild summers and winters, and temperatures ranging from 3°C (37°F) in winter to 16°C (61°F) in summer. ",
      },
      {
        city: "Eindhoven",
        description:
          "Eindhoven, located in the southern Netherlands, is known as a technology and design hub, often referred to as the 'Silicon Valley' of the Netherlands. It is home to Philips Electronics and the Design Academy Eindhoven. Eindhoven has a temperate maritime climate with mild winters and relatively warm summers, with temperatures ranging from about 2°C (36°F) in winter to 17°C (63°F) in summer. ",
      },
      {
        city: "Utrecht",
        description:
          "Utrecht, situated in the central Netherlands, is noted for its historic city center, which includes medieval buildings, canals, and the Dom Tower, the tallest church tower in the Netherlands. Utrecht has a vibrant student population due to Utrecht University. The city has a temperate maritime climate with mild winters and warm summers, with temperatures ranging from around 3°C (37°F) in winter to 18°C (64°F) in summer.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "Delft University of Technology (TU Delft)",
        description:
          "Situated in Delft, South Holland, Delft University of Technology (TU Delft) is a prestigious university renowned for its engineering and technology programs. TU Delft, the biggest and most established technical institution in the Netherlands, is a leader in disciplines like industrial design, architecture, and engineering. It is well known throughout the world for its superior research and focus on sustainability and innovation. The advanced facilities, broad and lively academic environment, and solid industry contacts of TU Delft are advantageous to international students. Industrial design, aeronautical engineering, and civil engineering are among the most popular courses. The university offers exceptional chances for both professional and personal growth to overseas students because of its well-known worldwide reputation and collaborative approach to education.",
        logo: DelftLogo,
      },
      {
        university: "Leiden University",
        description:
          "Founded in 1575, Leiden University is the oldest institution in the Netherlands and is situated in Leiden, South Holland. It stands out for doing research and providing instruction in a wide range of academic fields, such as the social sciences, natural sciences, and humanities. The university is renowned for encouraging a dynamic academic atmosphere with its strong emphasis on multidisciplinary studies and rich historical past. Leiden University offers a collaborative approach to teaching, a high reputation, and ample research opportunities that are advantageous to international students. The university provides well-known courses in disciplines like molecular science, law, and archaeology. Consistently receiving top ranks and making significant contributions to research strengthen its reputation globally.",
        logo: LeidenLogo,
      },
      {
        university: "Eindhoven University of Technology",
        description:
          "One of the top universities for engineering, technology, and applied sciences is Eindhoven University of Technology (TU/e), which is situated in Eindhoven, North Brabant. The university stands out for its emphasis on research with real-world applications and solid industrial ties. TU/e's focus on innovation and technology is seen in its cooperative projects with business partners. Modern facilities, chances for cutting edge research, and an emphasis on highly practical learning are also advantageous to international students. According to the QS World University Rankings, TU Eindhoven is listed among the best technical universities in Europe and is among the top 150 universities worldwide for engineering and technology.",
        logo: EindhovenLogo,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };
  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>
          <p>
            International students must often meet a number of eligibility
            requirements before enrolling in a Canadian university. While
            particular criteria may differ by university and program.Some of the
            eligibilities are as following:
          </p>
          <ul>
            <li>
              An international student to study an undergraduate degree must be
              between the ages of 17 to 19 years old.
            </li>
            <li>
              For undergraduate programs, you must have completed secondary
              school education equivalent to the Canadian high school diploma.
            </li>
            <li>
              For master’s there is no age limit and the international students
              are allowed to study a diploma or master 's course in any Canadian
              universities.
            </li>
          </ul>
          <h6>Documents required</h6>
          <p>
            An international student must submit the following documents to file
            a Canadian student visa:.
          </p>
          <ul>
            <li>
              Official letter of acceptance from the recognised Canadian
              educational institutions.
            </li>
            <li>
              A valid passport and recent passport-sized photographs of the
              student.
            </li>
            <li>
              Evidence of financial support to support the students educational
              fees, living expenses, and transportation expenses. Documents such
              as bank statements, scholarship letters, and affidavits of
              support.
            </li>
            <li>
              A proper application form has to be filled out and signed (IMM
              1294) or any other additional forms have to be completed by the
              student.
            </li>
            <li>
              A medical and police clearance certificate from the respective
              home country has to be obtained by the international student to
              get the study permit visa from Canada.
            </li>
          </ul>

          <h6>English proficiency</h6>
          <p>
            Canadian universities recognise IELTS, TOEFL (IBT), and PTE. The
            applicants must obtain the appropriate score on the English
            proficiency test. An individual must score at least 6.5 bands on the
            IELTS. To be accepted into educational institutions, the TOEFL score
            should be between 80 and 100, and the PTE score should be between 58
            and 65.
          </p>
          <h6>Application</h6>
          <p>
            The application fee for a Canada study visa costs CAD $150. The
            student or applicant has to create an account on Refugees and
            Citizenship Canada. After which, the documents have to be uploaded,
            the required documents as mentioned above sent, and along with (IMM
            1294) with accurate information and submitted to the nearest
            Canadian visa office in the country.
          </p>
          <h6>Decision</h6>
          <p>
            Check your application's status on a frequent basis. Processing
            timeframes vary according to the visa office and the number of
            applications.Decision: Once granted, you will be issued a study
            permit that allows you to study in Canada. If additional documents
            or an interview are required, the visa office will notify
            you.Approximately, the time period for granting the visa processing
            is around 1 to 3 months or more.
          </p>
        </div>
      </div>

      <FeeStructureTables countryName={"Netherlands"} />
    </div>
  );
}

export default Netherlands;
