import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

//icons
import innovationIcon from "../../../Assets/innovation.png";
import startUpIcon from "../../../Assets/startUp.png";
import bilingual from "../../../Assets/bilingual.png";
import immigration from "../../../Assets/immigration.png";
import entrepreneur from "../../../Assets/entrepreneur.png";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

//university logos
import torontoUniv from "../../../Assets/IvyLeague/UOT.png";
import mcgillUniv from "../../../Assets/IvyLeague/mcgill.jpg";
import waterlooUniv from "../../../Assets/IvyLeague/waterloo.png";
import UBCUniv from "../../../Assets/IvyLeague/UBC.png";
import QueensCanada from "../../../Assets/IvyLeague/QueensCanada.png";

function Canada() {
  const data = {
    countryName: `Canada`,
    titleTag: `Study In Canada For International Students | Cost of Living in Canada | Why study in  | Confluence Educational Services`,
    aboutCountry: `As per the latest update there are around 2,00,000 international students enrolling into Canadian universities each year. Canada has more than 90 public universities, with 30 plus ranked educational institutions in the global top 500.Geographically, Canada extends from the Atlantic to the Pacific, with breathtaking natural features such as mountains, forests, and lakes.`,
    WhyStudyinThatCountry: [
      { icon: innovationIcon, text: "Innovation tech" },
      { icon: startUpIcon, text: "Start-up Ecosystem" },
      { icon: bilingual, text: "Bilingual Advantage" },
      { icon: immigration, text: "Canada’s Supportive Immigration" },
      { icon: entrepreneur, text: "Entrepreneurial Ventures" },
    ],
    TopCities: [
      {
        city: "Toronto",
        description:
          "City, capital of the Canadian territory of Ontario in the southeast region of Canada. It is the nation's most popular city of Canada. Toronto, Canada's largest city, has a population of around 3 million and encompasses 630 square kilometers on Lake Ontario's northwestern side. Toronto is home to iconic structures like the CN Tower and prestigious institutions like the University of Toronto.",
      },
      {
        city: "Vancouver",
        description:
          "Vancouver is a city in southwestern British Columbia, Canada. It serves as the primary urban core of western Canada and the focal point of one of the country's most populous metropolitan areas. Vancouver is located between Burrard Inlet (an arm of the Strait of Georgia) to the north and the Fraser River delta to the south, opposite Vancouver Island. University of British Columbia (UBC) and Vancouver Island University (VIU), one of the famous universities, are located in this city.",
      },
      {
        city: "Calgary",
        description:
          "Calgary, located in southern Alberta, Canada, near the Rocky Mountains' foothills, is a thriving city recognized for its natural beauty and vibrant culture. The city is notable for hosting the Calgary Stampede, a major annual rodeo and festival celebrating Western history. Calgary is also a gateway to the breathtaking Canadian Rockies, offering easy access to Banff, Lake Louise, and world-class skiing. ",
      },
      {
        city: "Edmonton",
        description:
          "Edmonton, Alberta's capital city, is situated along the North Saskatchewan River in the province's centre. Edmonton is known as 'Canada's Festival City,' and it hosts various festivals throughout the year, including the Edmonton International Fringe Theatre Festival, which is one of the largest of its kind in North America. The city is also notable for West Edmonton Mall, which is one of the world's largest shopping and entertainment complexes.",
      },
      {
        city: "Montreal",
        description:
          "Montreal, located on the Island of Montreal in Quebec, is one of Canada's most lively and culturally diverse cities. The city is well-known for its European flair and for its events, such as the world-renowned Montreal Jazz Festival and the Just for Laughs Comedy Festival. Montreal is also a gastronomic hotspot, known for its diversified cuisine scene, which includes popular dishes such as poutine and Montreal-style bagels.",
      },
      {
        city: "Quebec City",
        description:
          "Quebec City is located in eastern Canada. Quebec City is close to all long weekend trips. Its history-friendly vibe and myriad attractions make it stand out as a travel destination throughout the year. The city also preserves its heritage and works towards sustainable tourism. Québec City's educational institutions welcome those seeking to complete training programs, including study abroad, vocational retraining, and job placement.",
      },
    ],

    IVYLeagueColleges: [
      {
        university: "University of Toronto",
        description:
          "The University of Toronto, founded in 1827, is Canada's leading university, consistently ranked among the top 20 in the world. Located in downtown Toronto, the University of Toronto provides world-class research, diverse programs, and a dynamic campus life. Its excellent academic standards, distinguished professors, and strong global reputation draw students from all over the world. The University of Toronto's substantial resources, innovative environment, and strategic position in Canada's largest metropolis make it a popular choice among students.",
        logo: torontoUniv,
      },
      {
        university: "McGill University",
        description:
          "McGill University, founded in 1821, is one of Canada's best universities, consistently ranking among the world's top 50 institutions. McGill University, located in Montreal, Quebec, is well-known for its challenging academics, innovative study methods, and prominent programs, particularly in medicine and law. Its diversified and international environment, as well as its strong worldwide reputation, attract students from around the world. McGill's historic campus, dynamic student life, and dedication to quality make it the top choice for individuals looking for a world-class education in Canada.",
        logo: mcgillUniv,
      },
      {
        university: "University of Waterloo",
        description:
          "The University of Waterloo, located in Waterloo, Ontario, is well-known for its strong engineering, computer science, and co-op programs. It advantages international students with its vast co-op possibilities, which provide important work experience and networking. The university is well-known for its innovation and research impact, and it has routinely ranked among the top Canadian and international universities. The Waterloo Engineering Society and the International Students' Association are two notable student clubs that contribute to the campus community. Waterloo is also known for its entrepreneurial culture and strong linkages to industry, which contribute to its excellent worldwide and national rating.",
        logo: waterlooUniv,
      },
      {
        university: "University of British Columbia (UBC)",
        description:
          "The University of British Columbia (UBC), based in Vancouver, British Columbia, is well-known for its outstanding programs in environmental sciences, engineering, and business. International students benefit from UBC's broad population, numerous research opportunities, and cutting-edge facilities. The university is consistently regarded as one of the top institutions in the world and in Canada. Notable clubs include the UBC International Students' Association and the UBC Business Club, both of which promote campus life and networking. UBC is known for its global impact, innovation, and gorgeous campus, all of which contribute to its excellent international rankings.",
        logo: UBCUniv,
      },
      {
        university: "Queen's University",
        description:
          "Queen's University in Kingston, Ontario, provides extensive support to overseas students through its overseas Centre, which offers academic advice, visa assistance, and cultural integration activities. Orientation seminars help students adjust to campus life, and significant academic resources, including libraries and tutoring services, are easily available. The institution encourages social relationships through clubs such as the Queen's International Students Association and offers job development possibilities through internships and co-op programs. This comprehensive support system enables overseas students to assimilate seamlessly, thrive academically, and get significant Canadian employment experience.",
        logo: QueensCanada,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };
  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>
          <p>
            International students must often meet a number of eligibility
            requirements before enrolling in a Canadian university. While
            particular criteria may differ by university and program.Some of the
            eligibilities are as following:
          </p>
          <ul>
            <li>
              An international student to study an undergraduate degree must be
              between the ages of 17 to 19 years old.
            </li>
            <li>
              For undergraduate programs, you must have completed secondary
              school education equivalent to the Canadian high school diploma.
            </li>
            <li>
              For master’s there is no age limit and the international students
              are allowed to study a diploma or master 's course in any Canadian
              universities.
            </li>
          </ul>
          <h6>Documents required</h6>
          <p>
            An international student must submit the following documents to file
            a Canadian student visa:.
          </p>
          <ul>
            <li>
              Official letter of acceptance from the recognised Canadian
              educational institutions.
            </li>
            <li>
              A valid passport and recent passport-sized photographs of the
              student.
            </li>
            <li>
              Evidence of financial support to support the students educational
              fees, living expenses, and transportation expenses. Documents such
              as bank statements, scholarship letters, and affidavits of
              support.
            </li>
            <li>
              A proper application form has to be filled out and signed (IMM
              1294) or any other additional forms have to be completed by the
              student.
            </li>
            <li>
              A medical and police clearance certificate from the respective
              home country has to be obtained by the international student to
              get the study permit visa from Canada.
            </li>
          </ul>

          <h6>English proficiency</h6>
          <p>
            Canadian universities recognise IELTS, TOEFL (IBT), and PTE. The
            applicants must obtain the appropriate score on the English
            proficiency test. An individual must score at least 6.5 bands on the
            IELTS. To be accepted into educational institutions, the TOEFL score
            should be between 80 and 100, and the PTE score should be between 58
            and 65.
          </p>
          <h6>Application</h6>
          <p>
            The application fee for a Canada study visa costs CAD $150. The
            student or applicant has to create an account on Refugees and
            Citizenship Canada. After which, the documents have to be uploaded,
            the required documents as mentioned above sent, and along with (IMM
            1294) with accurate information and submitted to the nearest
            Canadian visa office in the country.
          </p>
          <h6>Decision</h6>
          <p>
            Check your application's status on a frequent basis. Processing
            timeframes vary according to the visa office and the number of
            applications.Decision: Once granted, you will be issued a study
            permit that allows you to study in Canada. If additional documents
            or an interview are required, the visa office will notify
            you.Approximately, the time period for granting the visa processing
            is around 1 to 3 months or more.
          </p>
        </div>
      </div>

      <FeeStructureTables countryName={"Canada"} />
    </div>
  );
}

export default Canada;
