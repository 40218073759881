import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Separator from "../../Assets/texticon-1.webp";
import separatorFallBack from "../../Assets/texticon-1.jpg";

import "./coursescard.css";
import { BsBank } from "react-icons/bs";
import {
  GiLargeDress,
  GiCirclingFish,
  GiPaintBrush,
  GiMagnifyingGlass,
} from "react-icons/gi";
import { PiPlant } from "react-icons/pi";

import {
  MdNaturePeople,
  MdBusinessCenter,
  MdDesignServices,
  MdEngineering,
  MdSportsTennis,
} from "react-icons/md";
import {
  FaPenFancy,
  FaBook,
  FaBalanceScale,
  FaLanguage,
  FaFilm,
} from "react-icons/fa";

import {
  FaPlaneDeparture,
  FaChartLine,
  FaFlaskVial,
  FaComputer,
  FaSuitcaseMedical,
  FaHandHoldingDollar,
  FaPeopleGroup,
  FaEarthAsia,
  FaEarthAfrica,
} from "react-icons/fa6";

function Coursescards() {
  const [cardName, setCardName] = useState([
    {
      coursename: "Account and Finance",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=Australia&course=Accounting%20and%20Finance",
      icon: <BsBank />,
    },
    {
      coursename: "Business Studies",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=Australia&course=Business%20Studies",
      icon: <MdBusinessCenter />,
    },
    {
      coursename: "Chemistry",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Chemistry",
      icon: <FaFlaskVial />,
    },
    {
      coursename: "Computer Science",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Computer%20Science",
      icon: <FaComputer />,
    },
    {
      coursename: "Creative Writing",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Creative%20Writing",
      icon: <FaPenFancy />,
    },
    {
      coursename: "Design",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Design",
      icon: <MdDesignServices />,
    },
    {
      coursename: "Education",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=Australia&course=Education",
      icon: <FaBook />,
    },
    {
      coursename: "Engineering",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Engineering",
      icon: <MdEngineering />,
    },
    {
      coursename: "Language Studies",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Language%20Studies",
      icon: <FaLanguage />,
    },
    {
      coursename: "Fashion",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Fashion",
      icon: <GiLargeDress />,
    },
    {
      coursename: "Hospitality and Tourism",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Hospitality%20%26%20Tourism",
      icon: <MdNaturePeople />,
    },
    {
      coursename: "Communications",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Communications",
      icon: <FaPeopleGroup />,
    },
    {
      coursename: "Law",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=Australia&course=Law",
      icon: <FaBalanceScale />,
    },
    {
      coursename: "Economics",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Economics",
      icon: <FaHandHoldingDollar />,
    },
    {
      coursename: "Statistics",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Statistics",
      icon: <FaChartLine />,
    },
    {
      coursename: "Math and Science",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Math%20and%20Science",
      icon: <MdNaturePeople />,
    },
    {
      coursename: "Film & Media Studies",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Film%20and%20Media%20studies",
      icon: <FaFilm />,
    },
    {
      coursename: "Medical & Health Sciences",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Medical%20%26%20Health%20Sciences",
      icon: <FaSuitcaseMedical />,
    },
    {
      coursename: "Psychology",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=Australia&course=Psychology",
      icon: <MdNaturePeople />,
    },
    {
      coursename: "Marine",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Marine",
      icon: <GiCirclingFish />,
    },
    {
      coursename: "Arts and Humanities",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Arts%20and%20Humanit",
      icon: <GiPaintBrush />,
    },
    {
      coursename: "Archaeology",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Archaeology",
      icon: <GiMagnifyingGlass />,
    },
    {
      coursename: "Geography",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20States%20of%20America&course=Geography",
      icon: <FaEarthAsia />,
    },
    {
      coursename: "Aeronautics and Aviation Science",
      link: "https://www.confluenceedu.com/",
      icon: <FaPlaneDeparture />,
    },
    {
      coursename: "Agriculture and Horticulture",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=Australia&course=Agriculture,%20Horticulture",
      icon: <PiPlant />,
    },
    {
      coursename: "Environment Science and Health",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=Australia&course=Environmental%20Science%20and%20Health",
      icon: <FaEarthAfrica />,
    },
    {
      coursename: "Sports",
      link: "https://dashboard.confluenceedu.com/courses?degree=Undergraduate&country=United%20Kingdom&course=Sports",
      icon: <MdSportsTennis />,
    },
    {
      coursename: "Pharmacy",
      link: "https://www.confluenceedu.com/destination/Study-in-United-States-of-America",
      icon: <FaSuitcaseMedical />,
    },
  ]);

  const clicked = (link) => {
    return () => {
      window.open(link, "_blank");
    };
  };

  return (
    <div className="cards__page p-4 pt-1">
      <div className="text-center">
        <h1>Popular Disciplines</h1>

        <picture>
          <source srcSet={Separator} type="image/webp" />
          <img
            src={separatorFallBack}
            alt="separator"
            className="image__separator d-block my-3 mx-auto"
          />
        </picture>
      </div>
      <div className="cards_menu d-grid col-md-auto gap-3">
        {cardName.map((value, index) => {
          return (
            <div className="m-1" key={index + 1} onClick={clicked(value.link)}>
              <Card className="p-3 py-md-3 coursesCards">
                <div className="text-center d-flex ">
                  <div className="icon pe-2 fs-3 align-items-center ">
                    {value.icon && value.icon}
                  </div>
                  <Card.Text className="text-center pt-3 fw-bold courseName">
                    {value.coursename}
                  </Card.Text>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Coursescards;
