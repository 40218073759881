import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

//icons
import Progress from "../../../Assets/personel growth.png";
import climate from "../../../Assets/climate.png";
import english from "../../../Assets/English.jpg";
import entrepreneur from "../../../Assets/entrepreneur.png";
import nature from "../../../Assets/nature.png";

//university logos
import AuklnadLogo from "../../../Assets/IvyLeague/aucklandUniv.png";
import OtagaUniv from "../../../Assets/IvyLeague/OtagaUniv.png";
import CanterburyLogo from "../../../Assets/IvyLeague/CanterburyLogo.jpg";
import wellingtonLogo from "../../../Assets/IvyLeague/wellingtonLogo.webp";
import AutLogo from "../../../Assets/IvyLeague/AutLogo.png";

function Newzealand() {
  const data = {
    countryName: `Newzealand`,
    titleTag: `Study In Newzealand For International Students | Cost of Living in Newzealand | Why study in  | Confluence Educational Services`,
    aboutCountry: `With over 69,000 international students in 2023, New Zealand's higher education sector experienced a considerable increase. This is a 67% increase over 2022 and 60% of the pre-pandemic figures from 2019. Indeed, the country is growing increasingly popular among applicants from over the world.
`,
    WhyStudyinThatCountry: [
      { icon: Progress, text: "Progressive Career" },
      { icon: nature, text: "Agriculture" },
      { icon: climate, text: "Impeccable climate" },
      { icon: english, text: "Seamless Communication" },
      { icon: entrepreneur, text: "Eminent Education" },
    ],
    TopCities: [
      {
        city: "Auckland",
        description:
          "Located in the northern part of New Zealand’s North Island, Auckland is the largest city in the country and a major economic hub. Known as the 'City of Sails' due to its stunning harbor and abundant sailing opportunities, Auckland offers a diverse range of activities, from exploring its vibrant arts scene to visiting landmarks like the Sky Tower and the Auckland War Memorial Museum. The city enjoys a temperate climate with mild, damp winters and warm, humid summers.",
      },
      {
        city: "Wellington",
        description:
          "Situated at the southern tip of New Zealand’s North Island, Wellington is the capital city and the political center of the country. Famous for its vibrant arts and cultural scene, Wellington is home to the Te Papa Tongarewa (Museum of New Zealand) and numerous theaters and galleries. The city is also known for its picturesque harbor and rugged coastal landscapes. Wellington’s climate is characterized by mild temperatures year-round, with cool, wet winters and warm, breezy summers.",
      },
      {
        city: "Christchurch",
        description:
          "Located on the South Island, Christchurch is the largest city in the Canterbury region and is often referred to as the 'Garden City' due to its many parks and botanical gardens. The city is known for its innovative approach to urban redevelopment following the 2011 earthquake, with new buildings and public spaces showcasing modern design. Christchurch has a temperate climate with cool winters and warm summers.",
      },
      {
        city: "Queenstown",
        description:
          "Nestled in the Southern Alps on New Zealand’s South Island, Queenstown is renowned for its breathtaking scenery and adventure tourism. Often referred to as the 'Adventure Capital of New Zealand,' Queenstown offers a wide range of outdoor activities, including bungee jumping, skiing, and hiking. The city’s stunning location by Lake Wakatipu and its surrounding mountains provides a picturesque backdrop.",
      },
      {
        city: "Hamilton",
        description:
          "Located in the Waikato region on the North Island, Hamilton is known for its vibrant cultural scene and lush green spaces. The city is home to the Hamilton Gardens, a renowned collection of themed gardens that attract visitors from around the world. Hamilton also boasts a growing arts community and a variety of recreational activities. The city experiences a temperate climate with warm summers and mild winters.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "University of Auckland",
        description:
          "Located in Auckland, New Zealand’s largest city, the University of Auckland is renowned for its research excellence and comprehensive range of programs across various disciplines. It consistently ranks as New Zealand’s top university and is highly regarded globally, particularly in business, engineering, and medical fields. The university is known for its strong emphasis on research, with significant contributions to science, technology, and social issues.",
        logo: AuklnadLogo,
      },
      {
        university: "University of Otago",
        description:
          "Situated in Dunedin, the University of Otago is New Zealand's oldest university, recognized for its strong emphasis on health sciences, research, and a lively student community. It is particularly famous for its medical school, which is one of the leading medical institutions in the country. Otago's unique collegiate system fosters a close-knit community, enhancing the student experience. The university contributes significantly to research in health and sciences and has a rich tradition of community engagement and support.",
        logo: OtagaUniv,
      },
      {
        university: "University of Canterbury",
        description:
          "Located in Christchurch, the University of Canterbury is known for its strong engineering and science programs. It plays a crucial role in the recovery and rebuilding of Christchurch following the 2011 earthquakes, with significant contributions to engineering and infrastructure development. The university emphasizes research-led teaching and offers a supportive learning environment. It also engages actively with the local community and industries, fostering innovation and practical application of research.",
        logo: CanterburyLogo,
      },
      {
        university: "Victoria University of Wellington",
        description:
          "Victoria University of Wellington, situated in New Zealand’s capital, is renowned for its programs in law, humanities, and political science. The university has a strong focus on research with significant contributions to public policy and social issues, leveraging its proximity to government institutions. It is known for its vibrant campus life and engagement with cultural and political events. Victoria’s strong ties with the government and research communities enhance its impact and relevance. ",
        logo: wellingtonLogo,
      },
      {
        university: "Auckland University of Technology (AUT)",
        description:
          "Based in Auckland, AUT is recognized for its modern, industry-focused approach to education and strong emphasis on applied research. It offers innovative programs in fields like business, design, and engineering, with a focus on practical skills and real-world experience. AUT’s commitment to industry partnerships and research excellence contributes to its reputation. The university fosters a collaborative learning environment and actively engages with the local and global communities.",
        logo: AutLogo,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };

  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>

          <ul>
            <li>
              Ensure that the course or program you're enrolling in fits the New
              Zealand authorities' requirements for a student visa.
            </li>
            <li>
              The applicant must get an acceptance letter of enrollment from an
              accredited NewZealand Education Institutions.
            </li>
            <li>
              Demonstrate enough financial resources to cover the tuition and
              living cost and also get the health insurance from a private firm
              or through the Swiss National Health System.
            </li>
            <li>
              Police clearance certificate or similar documentation to show a
              clean record or history from the home country.
            </li>
          </ul>
          {/* <h6>Documents required</h6>
          <p>
            An international student must submit the following documents to file
            a Canadian student visa:.
          </p>
          <ul>
            <li>
              Official letter of acceptance from the recognised Canadian
              educational institutions.
            </li>
            <li>
              A valid passport and recent passport-sized photographs of the
              student.
            </li>
            <li>
              Evidence of financial support to support the students educational
              fees, living expenses, and transportation expenses. Documents such
              as bank statements, scholarship letters, and affidavits of
              support.
            </li>
            <li>
              A proper application form has to be filled out and signed (IMM
              1294) or any other additional forms have to be completed by the
              student.
            </li>
            <li>
              A medical and police clearance certificate from the respective
              home country has to be obtained by the international student to
              get the study permit visa from Canada.
            </li>
          </ul> */}

          <h6>English proficiency</h6>
          <p>
            Newzealand universities recognise IELTS, TOEFL (IBT), and PTE. The
            applicants must obtain the appropriate score on the English
            proficiency test. An individual must score at least 6.5 bands on the
            IELTS. To be accepted into educational institutions, the TOEFL score
            should be between 80 and 100, and the PTE score should be between 58
            and 65.
          </p>
          {/* <h6>Application</h6>
          <p>
            The application fee for a Canada study visa costs CAD $150. The
            student or applicant has to create an account on Refugees and
            Citizenship Canada. After which, the documents have to be uploaded,
            the required documents as mentioned above sent, and along with (IMM
            1294) with accurate information and submitted to the nearest
            Canadian visa office in the country.
          </p>
          <h6>Decision</h6>
          <p>
            Check your application's status on a frequent basis. Processing
            timeframes vary according to the visa office and the number of
            applications.Decision: Once granted, you will be issued a study
            permit that allows you to study in Canada. If additional documents
            or an interview are required, the visa office will notify
            you.Approximately, the time period for granting the visa processing
            is around 1 to 3 months or more.
          </p> */}
        </div>
      </div>

      <FeeStructureTables countryName={"Newzealand"} />
    </div>
  );
}

export default Newzealand;
