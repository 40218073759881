import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

//icons
import Health from "../../../Assets/healthcare.png";
import learning from "../../../Assets/IndependentLearning.png";
import landScape from "../../../Assets/nature.png";

//universityLogo
import ETHLogo from "../../../Assets/IvyLeague/ETHZurichLogo.png";
import UZHLogo from "../../../Assets/IvyLeague/UZHLogo.png";
import BaselLogo from "../../../Assets/IvyLeague/Basellogo.png";
import EPFLLogo from "../../../Assets/IvyLeague/EPFLLogo.jpg";
import Geneva from "../../../Assets/IvyLeague/Geneva.png";

function Switzerland() {
  const data = {
    countryName: `Switzerland`,
    titleTag: `Study In Switzerland For International Students | Cost of Living in Switzerland | Why study in  | Confluence Educational Services`,
    aboutCountry: `According to recent data, Switzerland hosts roughly 43,000 international students, indicating a steady increase in its popularity as a global study destination. Swiss universities continue to attract a varied foreign student body due to their excellent education, research possibilities, and high global rankings.
`,
    WhyStudyinThatCountry: [
      { icon: Health, text: "World Class Health System" },
      { icon: learning, text: "Learning Environment" },
      { icon: landScape, text: "Picturesque landscape" },
    ],
    TopCities: [
      {
        city: "Zurich",
        description:
          "Located in the central part of Switzerland, Zurich is the largest city and a major financial hub. Renowned for its well-preserved medieval old town, vibrant cultural scene, and high standard of living, Zurich is also home to leading institutions such as the University of Zurich and ETH Zurich, one of the world’s top universities. The city enjoys a temperate climate with cold winters and warm, pleasant summers. Zurich's beautiful lakeside setting and extensive public transportation make it a dynamic and accessible city.",
      },
      {
        city: "Geneva",
        description:
          "Situated on the shores of Lake Geneva in the southwestern part of the country, Geneva is known for its international significance, hosting numerous international organizations, including the United Nations and the Red Cross. The city is famous for its cultural diversity, luxury watchmaking, and high standard of living. Geneva offers beautiful views of the Alps, a temperate climate with mild winters and warm summers, and numerous parks and cultural institutions, such as the Musée d'Art et d'Histoire.",
      },
      {
        city: "Bern",
        description:
          "The capital city of Switzerland, Bern is located in the central part of the country and is renowned for its well-preserved medieval architecture. The city’s Old Town is a UNESCO World Heritage site, characterized by its historic buildings, cobblestone streets, and the Zytglogge clock tower. Bern is also known for its vibrant cultural scene, including theaters, museums, and the Bear Park. The city experiences a temperate climate with moderate temperatures year-round and is situated on a hill overlooking the Aare River.",
      },
      {
        city: "Basel",
        description:
          "Located in the northwestern part of Switzerland, Basel is known for its rich cultural heritage and is often considered the cultural capital of the country. The city is famous for its numerous museums, including the Kunstmuseum and the Fondation Beyeler, and its vibrant art scene. Basel is also known for its annual Art Basel fair, which attracts artists and collectors from around the world. The city enjoys a temperate climate with mild winters and warm summers, and it lies on the Rhine River, which adds to its scenic charm.",
      },
      {
        city: "Lausanne",
        description:
          "Situated on the northern shore of Lake Geneva, Lausanne is known for its picturesque setting and vibrant cultural life. The city is home to the International Olympic Committee headquarters and has a strong reputation for education and research, with institutions such as École Polytechnique Fédérale de Lausanne (EPFL). Lausanne’s old town is characterized by its historic buildings, and the city offers beautiful lake views and a mild climate with warm summers and cool winters.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "ETH Zurich (Swiss Federal Institute of Technology)",
        description:
          "ETH Zurich is well-known for its engineering, technology, and natural sciences degrees. It is constantly ranked among the world's top institutions due to its strong emphasis on research and innovation. Among its notable alumni are several Nobel laureates and significant people in science and industry.",
        logo: ETHLogo,
      },
      {
        university: "The University of Zurich (UZH)",
        description:
          "It is located in Zurich, Switzerland's largest city, which is known for its cultural liveliness and excellent standard of living. As one of Switzerland's oldest and most prominent universities, UZH provides a diverse range of programs in social sciences, humanities, and natural sciences. It is renowned for its robust research output and international academic relationships. International students benefit from a welcoming environment, ample academic resources, and opportunity for cross-cultural interaction. Zurich's outstanding public services, cultural attractions, and high standard of life contribute to a positive student experience at UZH.",
        logo: UZHLogo,
      },
      {
        university: "The University of Basel",
        description:
          "Located in Basel, Switzerland, the University of Basel is one of the country's oldest and most prestigious institutions, founded in 1460. It offers a diverse range of programs across fields such as life sciences, humanities, and social sciences. The university is renowned for its significant contributions to research and its strong international academic network. International students enjoy a rich academic environment with access to state-of-the-art facilities and extensive support services. Basel's vibrant cultural scene, historic charm, and high quality of life enhance the overall student experience, making it an attractive destination for global learners.",
        logo: BaselLogo,
      },
      {
        university: "École Polytechnique Fédérale de Lausanne (EPFL)",
        description:
          "The EPFL is located in Lausanne, Switzerland, on the beaches of Lake Geneva. EPFL is a premier public research university known for its excellence in engineering, technology, and life sciences. It provides cutting-edge education with an emphasis on innovation and research. International students benefit from a vibrant academic environment, cutting-edge facilities, and comprehensive support services. Lausanne's outstanding quality of life, gorgeous natural surroundings, and vibrant cultural scene all contribute to a positive EPFL experience.",
        logo: EPFLLogo,
      },
      {
        university: "The University of Geneva (UNIGE)",
        description:
          "The University of Geneva (UNIGE) is situated in Geneva, Switzerland, a global city renowned for its international organizations and cultural diversity. UNIGE, one of Switzerland's leading institutions, provides a diverse range of programs in the humanities, social sciences, and basic sciences. It is renowned for its research and academic achievements. International students at UNIGE benefit from a diverse and welcoming atmosphere, strong global connections, and comprehensive support services.",
        logo: Geneva,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };

  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>

          <ul>
            <li>
              Ensure that the course or program you're enrolling in fits the
              Swiss authorities' requirements for a student visa.
            </li>
            <li>
              The applicant must get an acceptance letter of enrollment from an
              accredited Swiss Education Institutions.
            </li>
            <li>
              Demonstrate enough financial resources to cover the tuition and
              living cost and also get the health insurance from a private firm
              or through the New Zealand Health System.
            </li>
            <li>
              Police clearance certificate or similar documentation to show a
              clean record or history from the home country.
            </li>
          </ul>

          <h6>English proficiency</h6>
          <p>
            Switzerland universities recognise IELTS, TOEFL (IBT), and PTE. The
            applicants must obtain the appropriate score on the English
            proficiency test. An individual must score at least 6.5 bands on the
            IELTS. To be accepted into educational institutions, the TOEFL score
            should be between 80 and 100, and the PTE score should be between 58
            and 65.
          </p>
          <h6>Application</h6>
          <p>
            Make an appointment with the New Zealand consulate or embassy in
            your home country.Attend your appointment, submit the visa
            application form along with the required documents, and pay the
            application Swiss student visa (Type D) CHF 100
          </p>
          <h6>Decision</h6>
          <p>
            Visa processing usually takes several weeks. Check with the
            consulate for specific processing times.Once approved, you’ll be
            notified to collect your visa. Ensure all details are correct before
            leaving the consulate.
          </p>
        </div>
      </div>

      <FeeStructureTables countryName={"Switzerland"} />
    </div>
  );
}

export default Switzerland;
