import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

//university logos
import GismaLogo from "../../../Assets/IvyLeague/GismaLogo.png";
import SchillerLogo from "../../../Assets/IvyLeague/Schiller_Logo.png";
import BSBILogo from "../../../Assets/IvyLeague/BSBILogo.png";
import NewEuropeanLogo from "../../../Assets/IvyLeague/NewEuropeanLogo.png";
import EUBSLogo from "../../../Assets/IvyLeague/EU_Business_School_logo.webp";
import UnivOfASLogo from "../../../Assets/IvyLeague/University_of_Europe_for_Applied_Sciences_logo.png";
import ArdenLogo from "../../../Assets/IvyLeague/ArdenLogo.png";
import SRHLogo from "../../../Assets/IvyLeague/SRHUniv.png";
import EBSLogo from "../../../Assets/IvyLeague/EBS_Universitaet_Logo.jpg";
import MacroMediaLogo from "../../../Assets/IvyLeague/macromediaUnivLogo.png";
import SteinBeisLogo from "../../../Assets/IvyLeague/SteinBeisLogo.png";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";

//icons
import workoppurtunity from "../../../Assets/work oppurtunity.png";
import scholorship from "../../../Assets/scholorship.png";
import workVisa from "../../../Assets/workVisa.png";
import culture from "../../../Assets/culture.png";
import DiverseAcademicProgram from "../../../Assets/book.png";
import worldclassEdu from "../../../Assets/icons8-university-48.png";

function Germany() {
  const data = {
    countryName: `Germany`,
    titleTag: `Study In Germany For International Students | Cost of Living in Germany | Why study in  | Confluence Educational Services`,
    aboutCountry: `As per the latest update there are around 2,00,000 international students enrolling into Canadian universities each year. Germany has more than 90 public universities, with 30 plus ranked educational institutions in the global top 500.Geographically, Germany extends from the Atlantic to the Pacific, with breathtaking natural features such as mountains, forests, and lakes.`,
    WhyStudyinThatCountry: [
      { icon: worldclassEdu, text: "World-Class Education" },
      { icon: DiverseAcademicProgram, text: "Diverse Academic Programs" },
      { icon: workoppurtunity, text: "Work Opportunities" },
      { icon: scholorship, text: "Scholarships and Financial Aid" },
      { icon: workVisa, text: "Post-Study Work Visa" },
      { icon: culture, text: "Rich Cultural Experience" },
    ],
    TopCities: [
      {
        city: "Berlin",
        description:
          "Berlin, the capital city, is known for its vibrant culture, historical significance, and world-class universities. It is a hub for international students and offers a wide range of academic programs and research opportunities.",
      },
      {
        city: "Munich",
        description:
          "Munich is famous for its high quality of life, excellent educational institutions, and strong economy. It is home to several top universities and offers numerous opportunities for internships and employment.",
      },
      {
        city: "Hamburg",
        description:
          "Hamburg, a major port city, offers a unique blend of tradition and modernity. It has several renowned universities and is known for its strong maritime and logistics industry.",
      },
      {
        city: "Frankfurt",
        description:
          "Frankfurt is Germany's financial hub and hosts a number of prestigious universities and business schools. It offers excellent opportunities for students interested in finance, business, and economics.",
      },
      {
        city: "Stuttgart",
        description:
          "Stuttgart is known for its engineering and automotive industry. It is home to several top universities and research institutions, making it an ideal city for engineering students.",
      },
      {
        city: "Cologne",
        description:
          "Cologne is famous for its cultural heritage, including the iconic Cologne Cathedral. It offers a diverse range of academic programs and has a vibrant student community.",
      },
    ],

    IVYLeagueColleges: [
      {
        university: "GISMA Business School",
        description: `Founded in 1999 it offers a range of business-related programs and is known for its focus on practical, real-world skills. It has campuses in Berlin and Hannover and provides programs in collaboration with prestigious universities.`,
        logo: GismaLogo,
      },
      {
        university: "Schiller International University",
        description:
          "Founded in 1964 it offers a global education with campuses in Germany, the USA, Spain, and France. It provides undergraduate and graduate programs in international relations, business, and management.",
        logo: SchillerLogo,
      },
      {
        university: "Berlin School of Business and Innovation",
        description:
          "BSBI was founded in 2018 it mainly  focuses on preparing students for the global job market with a range of business and management programs. It is located in the heart of Berlin, providing students with access to numerous networking opportunities.",
        logo: BSBILogo,
      },
      {
        university: "New European College",
        description:
          "New European College was founded in 2011 ,It offers business and management programs with a strong emphasis on practical experience. It is located in Munich and provides a supportive learning environment with small class sizes.",
        logo: NewEuropeanLogo,
      },
      {
        university: "EU Business School",
        description:
          "EU Business School  was started in 1973,It is an international business school with campuses in Germany, Spain, and Switzerland. It offers a range of undergraduate, graduate, and doctoral programs in business administration, finance, and management.",
        logo: EUBSLogo,
      },
      {
        university: "University of Applied Sciences Europe (UE)",
        description:
          "It was founded in the year of 2017 ,UE offers a variety of programs in business, sports, media, and design. It has campuses in Berlin, Hamburg, and Iserlohn, and is known for its practice-oriented approach to education.",
        logo: UnivOfASLogo,
      },
      {
        university: "Arden University",
        description:
          "Arden University was founded in the year of 1990 it  provides flexible and accessible education through online and blended learning programs. It offers a range of undergraduate and postgraduate programs in business, psychology, and law.",
        logo: ArdenLogo,
      },
      {
        university: "SRH Berlin University of Applied Sciences",
        description:
          "SRH Berlin University was established in the year of 2002,It  offers a wide range of programs in management, technology, media, and creative industries. It has a strong focus on innovation and entrepreneurship.",
        logo: SRHLogo,
      },
      {
        university: "EBS University",
        description:
          "EBS University Was founded in the year of 1971 it  is a private business school located in Wiesbaden and Oestrich-Winkel. It offers programs in business administration, law, and finance, and is known for its strong network of alumni and industry connections.",
        logo: EBSLogo,
      },
      {
        university: "Macromedia University of Applied Sciences",
        description:
          "Macromedia University was started in the year of 2006 it  offers programs in media, communication, management, and design. It has campuses in several German cities, including Munich, Berlin, and Cologne.",
        logo: MacroMediaLogo,
      },
      {
        university: "Steinbeis University",
        description:
          "Steinbeis University  was started in the year of 1998 it mainly focuses on project competence and provides programs in business and engineering. It offers a unique approach to education, combining academic study with practical projects.",
        logo: SteinBeisLogo,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Mechanical Engineering",
          "Electrical Engineering",
          "Civil Engineering",
          "Computer Engineering",
          "Automotive Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Nursing",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "Marketing",
          "Finance",
          "International Business",
          "Entrepreneurship",
          "Business Analytics",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Artificial Intelligence",
          "Data Science",
          "Software Engineering",
          "Cybersecurity",
          "Information Sysytems",
        ],
      },
    ],
  };
  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>
          <p>To apply for a student visa in Germany, you must:</p>
          <ul>
            <li>
              Have an acceptance letter from a recognized German university.
            </li>
            <li>APS Certificate</li>
            <li>
              Prove that you have sufficient financial resources to support
              yourself during your studies.
            </li>
            <li>Have health insurance coverage.</li>
            <li>
              Demonstrate proficiency in the language of instruction (German or
              English).
            </li>
          </ul>
          <h6>Documents required</h6>
          <p>
            An international student must submit the following documents to file
            a student visa:.
          </p>
          <ul>
            <li>Valid passport</li>
            <li>Completed visa application form</li>
            <li>Recent passport-sized photographs</li>
            <li>Acceptance letter from a German university</li>
            <li>Proof of sufficient financial resources</li>
            <li>Health insurance coverage</li>
            <li>Proof of language proficiency</li>
            <li>Academic transcripts and certificates</li>
            <li>Motivation letter</li>
            <li>Blocked Account 11904 Euros</li>
          </ul>
        </div>
      </div>

      <FeeStructureTables countryName={"Germany"} />
    </div>
  );
}

export default Germany;
