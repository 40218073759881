import FeeStructureTables from "../FeeStructureTable";
import CountryPage from "../CountryPage";
import "./countryImage.css";

//ivy league university logos
import MelbourneUniv from "../../../Assets/IvyLeague/UniversityOfMelbourne.svg";
import ANUniv from "../../../Assets/IvyLeague/ANU.svg";
import QueensUniv from "../../../Assets/IvyLeague/university-of-queensland.png";
import WesterbAustaliaUniv from "../../../Assets/IvyLeague/UWA.svg";
import southWhalesUniv from "../../../Assets/IvyLeague/UNSouthernWhales.png";
import AdelaideUniv from "../../../Assets/IvyLeague/UniversityofAdelaide.png";
import MonashUniv from "../../../Assets/IvyLeague/Monash.svg";

//icons
import universityIcon from "../../../Assets/icons8-university-48.png";
import bookIcon from "../../../Assets/book.png";
import workVisa from "../../../Assets/workVisa.png";
import growthIcon from "../../../Assets/personel growth.png";
import migrationIcon from "../../../Assets/migration.png";
import australia from "../../../Assets/australia.png";
import benefits from "../../../Assets/scholorship.png";

//courses iumages
import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";
import LAW from "../../../Assets/law.png";

function Australia() {
  const data = {
    countryName: `Australia`,
    titleTag: `Study In Australia For International Students | Cost of Living in Australia| Why study in Australia | Confluence Educational Services`,
    aboutCountry: `International education is Australia's fourth-largest export, generating over AUD 40 billion to the economy every year.Around 50,000 international graduates obtained post-study work visas in Australia.Approximately around 50,000 international graduates received post-study work permits in Australia attracted nearly 630,000 international students at all levels of education, making it one among the world's top international student destinations.
`,
    WhyStudyinThatCountry: [
      { icon: universityIcon, text: "High Quality of Education" },
      { icon: australia, text: "Notable Landmarks" },
      { icon: workVisa, text: "Global Employability" },
      { icon: bookIcon, text: "Improve English skills" },
      { icon: growthIcon, text: "Career Advancement" },
      { icon: migrationIcon, text: "Skilled Migration" },
      { icon: benefits, text: "Regional study Benefit" },
    ],
    TopCities: [
      {
        city: "Sydney",
        description:
          "In Australia, Sydney is the largest and the most recognisable city, with breathtaking views and world-famous buildings such as the Sydney Opera House and Sydney Harbour Bridge, along with beautiful beaches like Bondi, Palm, and Manly. Sydney, home to prestigious universities including the University of Sydney and the University of New South Wales (UNSW), is a popular destination for international students.",
      },
      {
        city: "Melbourne",
        description:
          "Melbourne is Australia's cultural capital, known for its thriving arts scene, diverse gastronomic choices, and athletic events. It is also recognised for its European-style architecture and vibrant coffee culture. The city is home to major institutions such as the University of Melbourne and Monash University, making it a popular choice for international students.",
      },
      {
        city: "Darwin",
        description:
          "Darwin, the capital of the Northern Territory, is noted for its tropical environment, cosmopolitan culture, and proximity to Southeast Asia. Charles Darwin University (CDU) is the city's primary university.",
      },
      {
        city: "Canberra",
        description:
          "Canberra, located in the country's southeast coastal area, is a relaxed, multicultural city with a population of more than 100,000. Each year, the city typically welcomes over 15,000 international students from more than 100 countries. The city is a hub for students studying health and sports science, with one of the lowest unemployment rates in Australia.",
      },
      {
        city: "Adelaide",
        description:
          "Adelaide is well-known for its festivals, food, and wine, and is considered one of Australia's most pleasant cities. It is smaller and more affordable than Sydney or Melbourne, but it offers a vibrant cultural environment. The city boasts a laid-back atmosphere and is conveniently located near wine regions such as the Barossa Valley and McLaren Vale. It's also recognised for its art festivals and historical buildings. The University of Adelaide and Flinders University are the city's premier institutions.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "The University of Melbourne",
        logo: MelbourneUniv,
        description:
          "The University of Melbourne is one of the oldest universities, founded in 1853, and is a public university located in Melbourne, Victoria. It is consistently ranked among the top universities globally and is the most prestigious university in Australia. The university is renowned for its strong global reputation, particularly for its Melbourne law and business schools.",
      },
      {
        university: "Australian National University (ANU)",
        logo: ANUniv,
        description:
          "Australian National University (ANU), located in Canberra, is one of Australia’s leading research institutions, known for its excellence in politics, international relations, and humanities. Established by the federal government, ANU offers world-class education, a vibrant campus life, and strong connections to government and industry.",
      },
      {
        university: "The University of Sydney",
        logo: "UniversitySydneyLogo",
        description:
          "The University of Sydney, Australia's first university, is renowned for its academic excellence and beautiful campus. Located in Sydney, it offers a wide range of programs and strong research facilities. The university excels in medicine, law, and business, and provides vibrant student life, extensive industry connections, and numerous scholarship opportunities for international students.",
      },
      {
        university: "The University of Queensland",
        logo: QueensUniv,
        description:
          "The University of Queensland (UQ) is a famous Brisbane-based institution known for its outstanding research and academic programs. UQ offers a wide range of undergraduate and postgraduate courses, primarily in science, engineering, and business. Its cutting-edge facilities, active campus culture, and strong industrial linkages draw students from all over the world.",
      },
      {
        university: "The University of Western Australia (UWA)",
        logo: WesterbAustaliaUniv,
        description:
          "The University of Western Australia (UWA) is a public university known for its research organization based in Perth, Western Australia. It provides a diverse selection of programs across multiple fields, attracting international students with globally recognized degrees. UWA offers a supportive environment, including personalized services, scholarships, and English language programs, making it an appealing option for international students.",
      },
      {
        university: "The University of New South Wales (UNSW Sydney)",
        logo: southWhalesUniv,
        description:
          "The University of New South Wales (UNSW Sydney) is a famous public university based in Sydney, NSW. UNSW is renowned for its strong emphasis on research and innovation, consistently ranking among Australia's and the world's best universities. For overseas students, UNSW provides a dynamic campus culture, substantial support services, and globally recognized study programs. The university is highly ranked in Australia, being within the top five universities, making it an appealing option for overseas students looking for a quality education and global employment prospects in a vibrant and multicultural city like Sydney.",
      },
      {
        university: "The University of Adelaide",
        logo: AdelaideUniv,
        description:
          "The University of Adelaide, located in Adelaide, South Australia, is known for its academic excellence and ranks as one of the top universities in Australia. It is a vibrant and affordable city, providing an ideal setting for students seeking a balanced lifestyle. The university has globally recognized programs and dedicated support services, making it a preferred choice for international students looking for a high-quality education.",
      },
      {
        university: "Monash University",
        logo: MonashUniv,
        description:
          "Monash University, founded in 1958, is a famous public university based in Melbourne, Australia. It is well-known for its outstanding programs in engineering, medicine, business, information technology, and arts. Monash is a member of the Group of Eight, which comprises Australia's major research universities. Monash University attracts international students because of its diverse and welcoming campus atmosphere, global network of campuses, and extensive research possibilities. The university offers extensive support services, scholarships, and places a significant emphasis on employment. Monash University, which is consistently ranked in the top five in Australia and the top 100 globally, provides a world-class education that prepares students for global job success.",
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "ENGINEERING",
        courses: [
          "Aerospace Engineering",
          "Mechanical Engineering",
          "Automation Engineering",
          "Civil Engineering",
          "Industrial Engineering",
          "Nuclear Engineering",
          "Chemical Engineering",
          "Electrical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
          "Sports Medicine",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "International Management",
          "Supply Chain",
          "Marketing",
          "Project Management",
          "Human Resources",
          "Finance",
          "Digital Marketing & E-Commerce",
          "International Tourism",
          "Sports Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Science",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Gender Studies",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
          "Construction Management",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
      {
        imageURL: LAW,
        department: "Law",
        courses: [
          "Criminal Justice",
          "Taxation",
          "Comparative and International Law",
          "Political Theory and Public Law",
          "Environmental and Resource Law",
          "Justice, Law and Crime Policy",
          "Indigenous Peoples Law and Policy",
        ],
      },
    ],
  };

  return (
    <div className="bg-white">
      <CountryPage data={data} />

      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>

          <ul>
            <li>
              The student should get an offer letter from the respective
              Australian educational institution and must receive a confirmation
              of enrolment, which is essential for visa applications.
            </li>
            <li>
              GTE (Genuine Temporary Entrant), where an international student
              must demonstrate that he or she is actually intending to stay in
              Australia for a temporary period of study.
            </li>
            <li>
              Financial checklist where the student has to show sufficient funds
              covering the tuition fee, living expenses, and travel costs. As
              per the latest updates, the approximate amount of funds required
              to show is AUD 24,505 per annum and the source of funds for
              personal savings.
            </li>
            <li>
              Health insurance, with all the listed check-ups by country, has to
              be completed by the student in their home country.
            </li>
            <li>
              The students below the age of 18 must provide proof of staying
              with their parents or guardians or any relative or having arranged
              accommodation from educational institutions.
            </li>
          </ul>
          <h6>Documents required</h6>
          <p>
            When individuals apply for a Student visa, they are required to
            submit the following documents:
          </p>
          <ul>
            <li>Valid Passport</li>
            <li>English proficiency score card</li>
            <li>Financial documents</li>
            <li>COE (Cofirmation of Enrollment)</li>
            <li>GTE (Genuine Temporary Entrant) statement</li>
            <li>OSHC evidence</li>
          </ul>

          <h6>English proficiency</h6>
          <p>
            Australian universities accept English proficiency test such as PTE
            - 58 to 65 , IELTS- 6.5 to 7.0 and TOEFL- 80 to 100 (center based
            test)
          </p>
          <h6>Application</h6>
          <p>
            The application fee for applying for an international student for
            Australia costs around AUD 650 per applicant which covers the
            biometrics and (OSHC) requirements.The application amount is
            non-refundable and must apply through These fees are generally
            non-refundable, and must apply through Australian Department of Home
            Affairs website.
          </p>
          <h6>Decision</h6>
          <p>
            The processing time for an Australian Student Visa (subclass 500) is
            normally 4 to 12 weeks. Application completeness, biometrics, health
            checks, and peak periods can all have an impact on this. Applying
            early and submitting appropriate documentation helps to avoid
            delays.
          </p>
        </div>
      </div>
      <FeeStructureTables countryName={"Australia"} />
    </div>
  );
}

export default Australia;
