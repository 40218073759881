import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

//icons
import innovationIcon from "../../../Assets/innovation.png";
import startUpIcon from "../../../Assets/startUp.png";
import bilingual from "../../../Assets/bilingual.png";
import immigration from "../../../Assets/immigration.png";
import entrepreneur from "../../../Assets/entrepreneur.png";

//university logos
import PolitecnicoLogo from "../../../Assets/IvyLeague/politecnicoLogo.jpg";
import sapienzaLogo from "../../../Assets/IvyLeague/sapienzaLogo.png";
import PadovaLogo from "../../../Assets/IvyLeague/PadovaLogo.png";
import TorinUniv from "../../../Assets/IvyLeague/TorinUniv.svg";
import BolognaUniv from "../../../Assets/IvyLeague/BolognaUniv.png";

function Italy() {
  const data = {
    countryName: `Italy`,
    titleTag: `Study In Italy For International Students | Cost of Living in Italy | Why study in  | Confluence Educational Services`,
    aboutCountry: `Italy has become a popular destination for overseas students, with an estimated 32,000 enrolled as of 2023. Major towns such as Rome, Milan, Bologna, and Florence are popular selections due to their outstanding universities and thriving student populations. Tuition fees in Italy are relatively low, ranging between €1,000 and €3,000 per year, depending on the program and institution.`,
    WhyStudyinThatCountry: [
      { icon: innovationIcon, text: "Innovation tech" },
      { icon: startUpIcon, text: "Start-up Ecosystem" },
      { icon: bilingual, text: "Bilingual Advantage" },
      { icon: immigration, text: "Italy’s Supportive Immigration" },
      { icon: entrepreneur, text: "Entrepreneurial Ventures" },
    ],
    TopCities: [
      {
        city: "Rome",
        description:
          "Rome is renowned for its historical and architectural wealth, with its historical center enclosed by the Aurelian Walls, reflecting nearly three thousand years of history. The city is a hub for scholarly and archaeological activities, hosting events like the 30th EAA Annual Meeting, which emphasized Rome's significance in historical and modern education. International students have opportunities to engage in world-class research and connect with specialists in their fields.",
      },
      {
        city: "Verona",
        description:
          "Verona provides a rich educational environment with a focus on humanities, arts, and history. The University of Verona offers strong programs in law, literature, and economics. The city's historical sites and cultural heritage offer an immersive learning experience, enhancing studies related to European history, architecture, and cultural studies. Verona's central location also facilitates access to major Italian cities for academic collaboration and cultural exchange.",
      },
      {
        city: "Turin",
        description:
          "Turin, the capital of Piedmont in northern Italy, is renowned for its sophisticated architecture and culinary arts. Known as the cradle of Italian independence, it houses prestigious institutions such as the University of Turin and the Turin Polytechnic. The city also boasts renowned museums like the Museo Egizio and the Mole Antonelliana.",
      },
      {
        city: "Milan",
        description:
          "Milan, the capital of Lombardy, is Italy's largest industrial city and a major center for design, fashion, and innovation. The city features a blend of ancient and modern architecture and hosts major events such as the Salone del Mobile. Milan's economic and cultural significance attracts designers, artists, and businesses from around the world.",
      },
      {
        city: "Florence",
        description:
          "Florence, founded as a Roman military colony, has a rich history as a republic, the seat of the duchy of Tuscany, and Italy's capital from 1865 to 1970. Located in a basin surrounded by hills, Florence experiences hot and humid summers and cool, wet winters. The city's historical significance and cultural heritage make it a key destination for students and researchers.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "Politecnico di Milano",
        description:
          "Located in Milan, Italy's financial and fashion center, Politecnico di Milano offers a stimulating learning environment with a strong focus on engineering, architecture, and design. The university is renowned for its civil, mechanical, and electrical engineering programs, as well as industrial and product design. It consistently ranks among Europe's top technological universities and is frequently listed in the top 20 globally for these subjects. With strong industry linkages, English-taught programs, and global collaboration opportunities, it is a top choice for international students.",
        logo: PolitecnicoLogo,
      },
      {
        university: "Sapienza University",
        description:
          "Sapienza University of Rome, one of Europe's oldest and largest universities, was founded in 1303. Known for its diverse range of programs, particularly in the arts, social sciences, and engineering, Sapienza consistently ranks among the world's top institutions, especially in classical and ancient history, archaeology, and physics. The university offers a multicultural environment with many courses taught in English, enriching students' academic experience with Rome's extensive cultural and historical resources.",
        logo: sapienzaLogo,
      },
      {
        university: "The University of Padua",
        description:
          "Founded in 1222 and based in Padua, Italy, the University of Padua is a public research university renowned for its excellent programs in science, engineering, medicine, and humanities. Known for its research quality and academic heritage, the university offers numerous programs and a dynamic student community. International students benefit from opportunities in Medicine, Engineering, and International Relations, enhancing their professional development and global networking.",
        logo: PadovaLogo,
      },
      {
        university: "The University of Turin",
        description:
          "Established in 1404 and located in Turin, Italy, the University of Turin is a public research university recognized for its strong programs in economics, law, and social sciences. It provides a rich academic environment with opportunities for interdisciplinary research and collaboration. International students gain from its extensive research facilities and diverse programs, especially in Economics, International Relations, and Law, preparing them for global career prospects.",
        logo: TorinUniv,
      },
      {
        university: "The University of Bologna",
        description:
          "Founded in 1088, the University of Bologna is one of the world's oldest public research universities. It is well-regarded for its diverse academic offerings and emphasis on research across various disciplines. International students benefit from programs in humanities and social sciences like Law and International Relations, as well as scientific and engineering fields like Biology and Computer Science. The university offers a variety of scholarships and financial support, including merit- and need-based awards and Erasmus+ funds.",
        logo: BolognaUniv,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };
  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>
          <p>
            The initial and most important criteria for obtaining a student visa
            in Italy are as follows:
          </p>
          <ul>
            <li>
              A valid passport for at least three months beyond the course or
              stay in Italy.
            </li>
            <li>
              The visa application form must be completed by the international
              student at the nearest Italian consulate or embassy in the
              individual country.
            </li>
            <li>
              An official admission letter from an Italian educational
              university demonstrating the financial condition and resources,
              indicating sufficient financial assistance to cover tuition and
              living expenses, and displaying a bank statement or proof of
              financial help or scholarships.
            </li>
            <li>
              Proof of residence is necessary and must be provided, such as a
              rental agreement, university housing, or any family residence.
            </li>
            <li>
              Consulates may request documentation as evidence of your flight
              ticket, along with two current passport-sized pictures.
            </li>
            <li>
              Provide certified copies of your academic qualifications, such as
              diplomas and transcripts, to prove your eligibility for the chosen
              course of study.
            </li>
            <li>
              Be prepared for an interview at the Italian consulate or embassy
              as part of the visa application procedure.
            </li>
          </ul>
          <h6>Documents required</h6>

          <ul>
            <li>A copy of Passport</li>
            <li>A copy of academic transcripts and diplomas [All levels]</li>
            <li>Copy of IELTS/TOEFL Score Card</li>
            <li>Passport Size Photograph</li>
            <li>Curriculum Vitae</li>
            <li>Letter of Motivation</li>
            <li>Recommendation Letters-3</li>
            <li>Portfolio for all fashion and designing courses</li>
          </ul>

          <h6>English proficiency</h6>
          <p>
            Italy universities recognise IELTS, TOEFL (IBT), and PTE. The
            applicants must obtain the appropriate score on the English
            proficiency test. An individual must score at least 6.5 bands on the
            IELTS. To be accepted into educational institutions, the TOEFL score
            should be between 80 and 100, and the PTE score should be between 58
            and 65.
          </p>
          <h6>Application</h6>
          <p>
            The visa application fee for an Italian student visa from India is
            typically around €50-60, which is approximately ₹4,000-5,000.
            However, this fee can vary slightly based on exchange rates and
            specific requirements. It's advisable to check with the nearest
            Italian consulate or embassy in India for the most up-to-date
            information on the exact cost.
          </p>
          <h6>Decision</h6>
          <p>
            A decision on a student visa application for Italy from the
            consulate in India usually takes 4 to 8 weeks. This duration varies
            depending on a number of circumstances, including the time of year,
            the thoroughness of the application, and the consulate's special
            procedures. To prevent delays, submit your application well in
            advance of your anticipated departure date, preferably 2-3 months
            before the start of your studies.
          </p>
        </div>
      </div>

      <FeeStructureTables countryName={"Italy"} />
    </div>
  );
}

export default Italy;
