import React from "react";
import CountryPage from "../CountryPage.js";
import FeeStructureTables from "../FeeStructureTable";
import "./countryImage.css";

//icons
import LanguageIcon from "../../../Assets/bilingual.png";
import GastronomyIcon from "../../../Assets/gastronomy.jpg";
import ClimateIcon from "../../../Assets/climate.png";
import LivingExpence from "../../../Assets/scholorship.png";
import musicalArt from "../../../Assets/musicalArt.png";
import Eiffel from "../../../Assets/eiffel tower.png";

//university logos
import IflLogo from "../../../Assets/IvyLeague/IfmLogo.webp";
import BeauxArtsLogo from "../../../Assets/IvyLeague/BeauxArtsLogo.jpg";
import EsmodLogo from "../../../Assets/IvyLeague/EsmodLogo.png";
import StudioBercotLogo from "../../../Assets/IvyLeague/StudioBercotLogo.svg";
import GrenobleLogo from "../../../Assets/IvyLeague/GrenobleLogo.svg";

import Engineering from "../../../Assets/engineering.jpg";
import HealthCare from "../../../Assets/healthCare.jpg";
import Business from "../../../Assets/Business.png";
import NaturalScience from "../../../Assets/naturalScience.jpg";
import Design from "../../../Assets/design.png";
import CreativeArts from "../../../Assets/CreativeArts.jpg";
import LiberalArts from "../../../Assets/LiberalArts.jpg";
import computerScience from "../../../Assets/computerScience.png";
import Architecture from "../../../Assets/Architecture.jpg";
import Pharmacy from "../../../Assets/pharmacy.jpg";

function France() {
  const data = {
    countryName: `France`,
    titleTag: `Study In France For International Students | Cost of Living in France | Why study in  | Confluence Educational Services`,
    aboutCountry: `France promotes a multicultural environment by offering a variety of programs in both English and French. Furthermore, students from all over the world are drawn to France because of its commitment to research, innovation, and academic quality.`,
    WhyStudyinThatCountry: [
      { icon: LanguageIcon, text: "Learn New Language" },
      { icon: GastronomyIcon, text: "Gastronomy hub" },
      { icon: LivingExpence, text: "Decent living expenses" },
      { icon: ClimateIcon, text: "Ideal Climate" },
      { icon: Eiffel, text: "Recognized for Tourism study" },
      { icon: musicalArt, text: "Profound Music art" },
    ],
    TopCities: [
      {
        city: "Paris",
        description:
          "Paris is France's capital city, located on the Seine River in northern France, at the center of the Île-de-France region. The Paris is one of the most densely inhabited places in Europe, with a population of approximately 12 million, including 10 million in the Paris urban area and the population of the commuter belt surrounding it.",
      },
      {
        city: "Lyon",
        description:
          "Lyon is known as the French capital of gastronomy.Lyon, in southeastern France, is a city renowned for its historical significance and vibrant culture. Once known as Lugdunum, it's a UNESCO World Heritage Site for its medieval and Renaissance architecture. A major economic hub, Lyon excels in banking, biotechnology, and textiles, especially silk.",
      },
      {
        city: "Toulouse",
        description:
          "Toulouse is a major city in the Occitanie region and is situated on the Garonne River in southwest France. It is well-known for its aerospace sector, housing the Airbus headquarters, and serving as a center of innovation and technology. With esteemed schools like the University of Toulouse and Toulouse School of Economics, the city provides top-notch education.",
      },
      {
        city: "Rennes",
        description:
          "Rennes, a city in northwest France's Brittany region, is well-known for its charming medieval district and active student population. With universities like the University of Rennes and the Rennes School of Business, which provide excellent degrees in a variety of subjects, the city is a key center for education. Rennes is known for its vibrant cultural scene, which includes festivals, medieval architecture, and a strong culinary scene.",
      },
      {
        city: "Nice",
        description:
          "Nice, a city in southeast France on the French Riviera, is well-known for its gorgeous Mediterranean coastline and pleasant weather. The city is home to well-known sites like the beaches along the Baie des Anges, the bustling Old Town (Vieux-Nice), and the Promenade des Anglais. With institutions like the Musée Matisse and Musée Marc Chagall, Nice is a center for art and culture. It is renowned for its extensive gastronomic scene, which includes regional specialties and fresh seafood.",
      },
      {
        city: "Nantes",
        description:
          "Nantes, located in western France on the Loire River, is known for its vibrant cultural scene and maritime history. The city boasts attractions like the Château des Ducs de Bretagne, which highlights its historical significance, and the Machines of the Isle of Nantes, an innovative art project showcasing mechanical creations. Nantes has a strong emphasis on sustainability and urban development, making it a progressive and livable city.",
      },
      {
        city: "Marseille",
        description:
          "Marseille, France's second-largest city, is located on the Mediterranean coast. Known for its historic Old Port (Vieux-Port) and vibrant multicultural atmosphere, Marseille offers a rich blend of history and modernity. Key landmarks include the Basilique Notre-Dame de la Garde, which provides panoramic city views, and the contemporary MuCEM (Museum of European and Mediterranean Civilizations). The city is also famous for its diverse cuisine, particularly seafood dishes like bouillabaisse.",
      },
      {
        city: "Strasbourg",
        description:
          "Strasbourg, a city on the Rhine River in northeastern France, is well-known for its thriving cultural scene and extensive history. The city is home to some of the best examples of medieval architecture, such as the magnificent Strasbourg Cathedral and the charming La Petite France neighborhood. The European Parliament has its seat in Strasbourg, emphasizing the importance of its political role. The city's food, culture, and bilingualism all showcase a fusion of French and German influences.",
      },
    ],
    IVYLeagueColleges: [
      {
        university: "Institut Français de la Mode (IFM, Paris)",
        description:
          "A prominent organization focused on fashion and design, the Institut Français de la Mode (IFM) in Paris was established in 1986 and is based in the heart of the world's fashion capital. It offers well-known bachelor's and master's degrees in strategy, management, and fashion design. IFM's innovative curriculum, strong industry ties, and the opportunity to study at the center of fashion innovation attract international students. The institution is renowned for its rigorous curriculum and for nurturing the next generation of fashion industry leaders. IFM provides a variety of financial aid and scholarship opportunities, including need- and merit-based funding.",
        logo: IflLogo,
      },
      {
        university: "École des Beaux-Arts (Paris)",
        description:
          "One of the most prominent and ancient art schools in France, the École des Beaux-Arts is located in Paris and was established in 1648. It offers courses in architecture, design, and fine arts. The École des Beaux-Arts is a popular choice among international students due to its extensive instruction in both traditional and contemporary techniques, rich artistic heritage, and significant influence on modern art and design. The institution is well-known for its rigorous curriculum and famous graduates who have made important contributions to the fields of art and design. Scholarships and grants are among the financial aid options available to help deserving students from around the world attend this prestigious institution.",
        logo: BeauxArtsLogo,
      },
      {
        university: "ESMOD International, Paris",
        description:
          "ESMOD International, a Paris-based fashion school founded in 1841, is one of the world's oldest and most prestigious. It offers specialized programs in Fashion Design and Creation, Fashion Business and Marketing, Pattern Making, and Fashion Styling. ESMOD is known for its strong industry connections, global network, and rigorous training. It has produced well-known designers such as Thierry Mugler and Olivier Rousteing. The school's emphasis on combining creative and technical skills makes it a popular choice for aspiring fashion professionals, offering a comprehensive education that prepares students for success in the field.",
        logo: EsmodLogo,
      },
      {
        university: "Studio Berçot",
        description:
          "Studio Berçot, a renowned fashion school in Paris, was founded in 1954 and is known for its focus on creativity and individuality. It offers a rigorous two-year program that emphasizes hands-on training in design, pattern-making, and styling, followed by a third year of internships. The school's project-based curriculum encourages students to develop their own unique style rather than follow industry trends. Studio Berçot provides internships and career placements through its extensive industry connections. Notable graduates include designers Isabel Marant and Martine Sitbon, making it an excellent choice for those seeking a distinctive approach to fashion education.",
        logo: StudioBercotLogo,
      },
      {
        university: "Université Grenoble Alpes",
        description:
          "Université Grenoble Alpes (UGA) is a top-tier French university known for its academic and scientific excellence. Located in Grenoble, it offers a diverse range of studies in science, technology, humanities, social sciences, and health. UGA is recognized for its focus on innovation and industry partnerships, with numerous cutting-edge research centers. The university has a broad and international student body, creating a dynamic and enriching academic environment. UGA's proximity to major scientific institutions and a strong technology sector makes it a hub for academic and professional opportunities.",
        logo: GrenobleLogo,
      },
    ],
    popularCourses: [
      {
        imageURL: Engineering,
        department: "Engineering",
        courses: [
          "Robotics",
          "Aerospace",
          "Mechanical Engineering",
          "Automation",
          "Civil Engineering",
          "Mechanical Engineering",
          "Structural Engineering",
          "Metallurgical Engineering",
        ],
      },
      {
        imageURL: HealthCare,
        department: "Health Care",
        courses: [
          "Medicine",
          "Biomedical Informatics",
          "Nursing",
          "Public Health",
          "Health Administration",
          "Nutrition",
          "Physical Therapy",
        ],
      },
      {
        imageURL: Business,
        department: "Business",
        courses: [
          "General Management",
          "International Management",
          "Marketing",
          "Strategic Management",
          "Human Resources",
          "Finance",
          "Consulting",
          "Operations Management ",
          "IT Management",
        ],
      },
      {
        imageURL: NaturalScience,
        department: "Natural Sciences",
        courses: [
          "Agricultural Biology",
          "Applied Mathematics and Statistics",
          "Microbiology",
          "Space Studies",
          "Neural Engineering",
          "Imaging Science",
          "Biotechnology",
          "Marine Science",
          "Genetics",
          "Geospatial Sciences",
        ],
      },
      {
        imageURL: Design,
        department: "Design",
        courses: [
          "Graphic Design",
          "UX/UI Design",
          "Fashion Design",
          "Interior Design",
          "Product Design",
          "Animation and Video Game Design",
        ],
      },
      {
        imageURL: CreativeArts,
        department: "CreativeArts",
        courses: [
          "Fine Arts",
          "Film Studies",
          "Photography",
          "Performing Arts",
          "Animation",
          "Culinary Arts",
        ],
      },
      {
        imageURL: LiberalArts,
        department: "Liberal Arts",
        courses: [
          "Psychology",
          "Sociology",
          "Anthropology",
          "Political Science",
          "Economics",
          "History",
          "International Relations",
          "Environmental Studies",
        ],
      },
      {
        imageURL: computerScience,
        department: "Computer Science",
        courses: [
          "Media Applications",
          "Game Development",
          "Databases & Data Mining",
          "Computational Mathematics",
          "Natural Language Processing",
          "Artificial Intelligence and Robotics",
          "Biocomputation",
          "Computer & Network Security",
        ],
      },
      {
        imageURL: Architecture,
        department: "Architecture",
        courses: [
          "Biodigital Architecture",
          "Interior Architecture, Interior Design and Decoration",
          "Ephemeral Architecture and Temporary Spaces",
          "Landscaping",
          "Urbanism Projects",
        ],
      },
      {
        imageURL: Pharmacy,
        department: "Pharmacy",
        courses: [
          "Pharmacology and Toxicology",
          "Pharmaceutical Engineering",
          "Pharmaceutics and Drug Delivery",
          "Molecular, Cellular and Biochemical Pharmacology",
          "Pharmacy Administration",
          "Biopharmaceutical Commercialization",
          "Pharmaceutical Manufacturing",
        ],
      },
    ],
  };

  return (
    <div className="bg-white">
      <CountryPage data={data} />
      <div className="p-4 applicationContent mx-auto mb-0">
        <div className="p-2 p-md-4">
          <h2 className="text-center countryPageHeading ">
            Student Visa procedure
          </h2>
          <h6>Eligibility</h6>

          <ul>
            <li>
              Individuals have to obtain the admit letter from the university of
              France to their application.Health insurance has to be obtained to
              cover all the medical expenses in France, and they must get it
              while in their home country or before their application for a
              visa.
            </li>
            <li>
              An international student must show sufficient funds to support
              their entire education, and a minimum of €615 per month is the
              average cost of living for the individuals in the country, France.
            </li>
            <li>
              An international student should have a confirmed accommodation on
              campus or any rental agreement document or any other accommodation
              confirmation.
            </li>
            <li>
              Before applying for a visa, a student must first register with
              Campus France, the body in charge of promoting higher education in
              France. Students will need to finish the Etudes en France
              procedure.
            </li>
            <li>
              Complete and sign the student visa application form, then make an
              appointment with the French consulate or visa center in your
              country to submit your application.
            </li>
          </ul>
          <h6>Documents required</h6>
          <ul>
            <li>Valid passport</li>
            <li>Passport first and last page</li>
            <li>Bachelors Provisional & Consolidated Marks Memo</li>
            <li>SOP</li>
            <li>CV (Received)</li>
            <li> Letter of Recommendation-2</li>
            <li>IELTS: Overall 6.5 band not less than 6.0</li>
            <li>Without IELTS: 12th English above 70% or Equivalent</li>
            <li>Gap Explanation if any</li>
            <li>Refusal or any Visa History</li>
          </ul>

          <h6>English proficiency</h6>
          <p>
            French universities recognise IELTS , TOEFL (IBT), and PTE. The
            applicants must obtain the appropriate score on the English
            proficiency test. An individual must score not less than 6.5 bands
            on the IELTS. To be accepted into educational institutions, the
            TOEFL score should be between 80 and 100, and the PTE score should
            be between 58 to 65.
          </p>
          <h6>Application</h6>
          <p>
            The student visa is normally valid for the duration of your
            education program, up to one year. If your studies last longer than
            the first visa period, you can renew it every year.
          </p>
          <h6>Decision</h6>
          <p>
            The decision period for a French student visa typically ranges from
            15 to 60 days, depending on factors like the country of application
            and the time of year. On average, it takes around 3 to 4 weeks for
            most applicants to receive a decision. It's recommended to apply at
            least 3 months before your intended travel date to allow for any
            potential delays.
          </p>
        </div>
      </div>

      <FeeStructureTables countryName={"France"} />
    </div>
  );
}

export default France;
